import {ConfirmModal, OutlineStat, PaginatedTable, TableHeader} from "components";
import React, {useEffect, useState} from "react";
import TableUtilities from "components/dist/tables/table-utilities";
import {Button, Card, Nav} from "react-bootstrap";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import {Pagination} from "components";
import ConsignorsService, {Consignor} from "../../modules/dashboard/consignors/consignors.service";
import {useParams} from "react-router-dom";
import {InventoryItem} from "../../modules/dashboard/inventory/inventory.service";
import EditConsignorModal from "../../modules/dashboard/consignors/components/EditConsignorModal";
import Consignors from "./consignors";
import ConsignorService from "./ConsignorService";
import {Helmet} from "react-helmet-async";

const tabs = {
    inventory: 'inventory',
    orders: 'orders'
} as const

type Tab = (typeof tabs)[keyof typeof tabs]


const ConsignorView = () => {
    const [selectedTab, setSelectedTab] = useState<Tab>(tabs.inventory);
    const [consignorDetails, setConsignorDetails] = useState<Consignor | null>(null);

    const [numOrders, setNumOrders] = useState<number>(0);
    const [numInventory, setNumInventory] = useState<number>(0);
    const [numInStockInventory, setNumInStockInventory] = useState<number>(0);

    const [revenue, setRevenue] = useState<number>(0);
    const [profit, setProfit] = useState<number>(0);
    const [items, setItems] = useState<number>(0);
    const [sales, setSales] = useState<number>(0);

    const [editConsignor, setEditConsignor] = useState<boolean>(false);

    const [messageVisible, setMessageVisible] = useState<boolean>(false);

    const {id} = useParams();

    const onDisableConsignors = async () => {
        await ConsignorService.disableConsignor(id!);
    }


    const headers: TableHeader[] = [
        {key: 'fullName', label: 'Name', rowClassName: 'min-w-125px', cellClassName: 'text-white'},
        {key: 'email', label: 'Email', rowClassName: 'min-w-125px'},
        {key: 'tier.name', label: 'Tier',},
        {key: 'createdAt', label: 'Joined At', format: (cell) => TableUtilities.formatDate(cell.item)},
        {key: 'status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)},
    ]

    const tableActions = () => (
        <>
            {/*<Button variant={'secondary'}>*/}
            {/*    <i className="ki-duotone ki-filter fs-2"><span className="path1"></span><span*/}
            {/*        className="path2"></span></i>*/}
            {/*    Filter*/}
            {/*</Button>*/}
        </>
    )

    const handleTabSelect = (eventKey: string | null) => {
        if (Object.values(tabs).includes(eventKey as Tab)) {
            setSelectedTab(eventKey as Tab);
        } else {
            console.error(`Unknown tab selected: ${eventKey}`);

            // Default to inventory tab
            setSelectedTab(tabs.inventory)
        }
    };

    const titleAndSize = (row: InventoryItem) => {
        return <div>
            <a className={'text-white d-block'} href={`/inventory/items/${row.id}`}>{row.product.title}</a>
            <span className={'mt-3'}>{row.size}</span>
        </div>
    }

    const ordersTitleAndSize = (row: any) => {
        return <div>
            <a className={'text-white d-block'} href={`/inventory/items/${row.item.id}`}>{row.item.product.title}</a>
            <span className={'mt-3'}>{row.item.size}</span>
        </div>
    }

    const inventoryHeaders: TableHeader[] = [
        {key: 'id', label: '', format: (cell) => TableUtilities.tableImage(cell.row.product.imageUrl, cell.item)},
        {key: 'size', label: 'Item Title', format: (cell) => titleAndSize(cell.row)},
        {key: 'shape', label: 'Condition', format: (cell) => <span className='text-capitalize'>{cell.item}</span>},
        {key: 'price', label: 'price', format: (cell) => TableUtilities.formatCurrencyCents(cell.item)},
        {key: 'profitAmount', label: 'Profit Total', format: (cell) => TableUtilities.formatCurrencyCents(cell.item)},
        {key: 'location.name', label: 'Location'},
        {key: 'createdAt', label: 'Created At', format: (cell) => TableUtilities.formatDate(cell.item)},
        {key: 'status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)}
    ]

    const ordersHeaders: TableHeader[] = [
        {key: 'id', label: '', format: (cell) => TableUtilities.tableImage(cell.row.item.product.imageUrl, cell.row.item.id)},
        {
            key: 'item.title',
            label: 'Item Title',
            format: (cell) => ordersTitleAndSize(cell.row)
        },
        {key: 'item.shape', label: 'Condition', cellClassName: 'text-capitalize'},
        {key: 'orderSummary.orderNumber', label: 'Order Id', cellClassName: 'text-capitalize'},
        {key: 'item.price', label: 'Price', format: (cell) => TableUtilities.formatCurrency(cell.item / 100)},
        {key: 'profitTotal', label: 'Profit', format: (cell) => TableUtilities.formatCurrency(cell.item / 100)},
        {key: 'item.status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)}
    ]

    const tableImage = (url: string) => {
        return <div style={{
            width: '50px',
            height: '50px',
            backgroundColor: 'white',
            borderRadius: '0.5em',
            backgroundImage: `url('${url}')`,
            backgroundSize: '80%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        }}>
        </div>
    }


    const onOrdersUpdated = (orders: any[]) => {
        setSales(orders.length)

        setProfit(orders.reduce((acc: number, item: any) => {
            return acc + item.profitTotal
        }, 0))

        setRevenue(orders.reduce((acc: number, item: any) => {
            return acc + item.item.price
        }, 0))
    }

    const handleOrdersPagination = (pagination: Pagination) => {
        setNumOrders(pagination.total)
    }

    const handleInventoryPagination = (pagination: Pagination) => {
        setNumInventory(pagination.total)
    }

    const onItemsUpdated = (items: any[]) => {
        setNumInStockInventory(items.filter(item => 
            item.status === 'active' || item.status === 'in_queue'
        ).length)
    }

    const handleEditConsignorClick = () => {
        setEditConsignor(true);
    }

    const handleEditConsignorClose = async (updated: boolean) => {
        // If the consignor was updated, re-fetch the data
        if (updated) {
            await fetchConsignor()
        }

        setEditConsignor(false);
    }

    const fetchConsignor = async () => {
        setConsignorDetails(await ConsignorsService.fetchConsignor(id!))
    }

    useEffect(() => {
        (async () => {
            fetchConsignor()
        })()
    }, [])


    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[{
                title: 'Consignors',
                path: '/consignors',
                isActive: false
            }]}>{intl.formatMessage({id: 'MENU.VIEW_CONSIGNOR'})}</PageTitle>

            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.VIEW_CONSIGNOR'})} - Bind</title>
            </Helmet>

            <Card className={'p-10 pb-0'} title=''>
                <div className='d-flex justify-content-between'>
                    <div className='d-block d-md-flex flex-column'>
                        <div className='d-flex align-middle'>
                            <h2 className='mb-1'>
                                {consignorDetails?.fullName}
                            </h2>

                            <div className='d-block d-md-inline ms-0 ms-md-3'>
                                {TableUtilities.statusBadge(consignorDetails?.status ?? '')}
                            </div>
                        </div>
                        <span className='text-gray-600 fw-bold d-block text-capitalize fs-7 mb-5'>
                            {consignorDetails?.tier?.name} - {consignorDetails?.userRef}
                        </span>
                        <div className="d-flex flex-wrap fw-bold fs-6 pe-2">
                            <div className="d-flex align-items-center text-gray-500 me-5 text-hover-primary">
                                <i className="ki-duotone ki-sms fs-4 me-1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                {consignorDetails?.email}
                            </div>
                            {consignorDetails?.phoneNumber && (
                                <div className="d-flex align-items-center text-gray-500 me-5 text-hover-primary">
                                    <i className="ki-duotone ki-sort fs-4 me-1">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                    </i>
                                    {consignorDetails.phoneNumber}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        {consignorDetails?.status !== 'disabled' && (
                            <Button variant={'secondary'} className='me-3' onClick={() => setMessageVisible(true)}>
                                Disable
                                <span className='d-none d-md-inline'>&nbsp;Consigner</span>
                            </Button>
                        )}
                        <Button onClick={handleEditConsignorClick} variant='primary' className='ml-auto'>
                            Edit
                            <span className='d-none d-md-inline'>&nbsp;Consigner</span>
                        </Button>
                    </div>
                </div>


                <div className='d-flex flex-row flex-wrap mt-15'>
                    <OutlineStat title={'Revenue'} value={TableUtilities.formatCurrencyCents(revenue)}/>
                    <OutlineStat title={'Profit'} value={TableUtilities.formatCurrencyCents(profit)}/>
                    <OutlineStat title={'In-Stock Items'} value={numInStockInventory.toString()}/>
                    <OutlineStat title={'Sales'} value={sales.toString()}/>
                </div>

                <div className='d-flex overflow-auto h-55px mt-10'>
                    <Nav activeKey={selectedTab}
                         onSelect={handleTabSelect}
                         className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>

                        <Nav.Item>
                            <Nav.Link eventKey={tabs.inventory}>Inventory</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={tabs.orders}>Orders</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </Card>

            <Card className='p-10 pt-5 mt-10'>
                <div style={{display: selectedTab === tabs.inventory ? 'block' : 'none'}}>
                    <PaginatedTable key={'inventory'} className={'mt-5'} dataProperty={'items'}
                                    paginate={true}
                                    onPaginationUpdated={handleInventoryPagination}
                                    headers={inventoryHeaders}
                                    dataUrl={`/api/v1/item/view/consignor/${id!}/all`}
                                    filterLocally={true} actions={tableActions()}
                                    onDataUpdated={onItemsUpdated}/>
                </div>

                <div style={{display: selectedTab === tabs.orders ? 'block' : 'none'}}>
                    <PaginatedTable key={'orders'} className={'mt-5'} headers={ordersHeaders}
                                    paginate={true}
                                    onPaginationUpdated={handleOrdersPagination}
                                    dataProperty={'orders'}
                                    dataUrl={`/api/v1/order/view/consignor/${id!}/all`}
                                    filterLocally={true} actions={tableActions()}
                                    onDataUpdated={onOrdersUpdated}/>
                </div>
            </Card>


            <ConfirmModal
                visible={messageVisible}
                onConfirm={onDisableConsignors}
                message={`Are you sure you want to disable this consignor?`}
                onCancel={() => setMessageVisible(false)}
            />

            {
                consignorDetails && <EditConsignorModal
                    consignor={consignorDetails}
                    show={editConsignor}
                    onClose={async (updated) => await handleEditConsignorClose(updated)}
                />
            }
        </>
    )
}

export default ConsignorView