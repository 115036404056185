import {Button, Card, Col, Nav, Row} from "react-bootstrap";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import TableUtilities from "components/dist/tables/table-utilities";
import {CatalogItemView, CatalogService, OutlineStat, PaginatedTable, Pagination, TableHeader} from "components";
import {InventoryItem} from "../../modules/dashboard/inventory/inventory.service";
import tableUtilities from "components/dist/tables/table-utilities";
import {toast} from "react-hot-toast";
import axios from "axios";
import product from "../labels/product";
import {PageTitle} from "../../../_metronic/layout/core";
import {Helmet} from "react-helmet-async";

const tabs = {
    inventory: 'inventory',
    orders: 'orders'
} as const

type Tab = (typeof tabs)[keyof typeof tabs]

const ProductPage = () => {
    const [selectedTab, setSelectedTab] = useState<Tab>(tabs.inventory);
    const [itemDetails, setItemDetails] = useState<CatalogItemView | null>(null);
    const [numOrders, setNumOrders] = useState<number>(0);
    const [numInventory, setNumInventory] = useState<number>(0);
    const [numInStockInventory, setNumInStockInventory] = useState<number>(0);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const [revenue, setRevenue] = useState<number>(0);
    const [profit, setProfit] = useState<number>(0);

    const {id} = useParams();

    useEffect(() => {
        (async () => {
            setItemDetails(await CatalogService.getItem(id!))
        })()
    }, [])

    const formatOwnership = (item: InventoryItem) => {
        if (item.storeOwned) {
            return 'Store Owned'
        } else {
            return <Link to={`/consignors/${item.user.id}`} className='text-gray-600 fw-bold'>{item.user.fullName}</Link>
        }
    }

    const formatPricePaid = (pricePaid: number | null) => {
        if (pricePaid) {
            return TableUtilities.formatCurrency(pricePaid / 100)
        } else {
            return '-'
        }
    }

    const searchValue = (searchTerm: string, value: InventoryItem) => {
        let searchString = JSON.stringify(value).toLowerCase()

        if (value.storeOwned) {
            searchString += ' store owned';
        }

        return searchString.includes(searchTerm);
    }
    // Size, Condition, Price, Profit Total, Ownership, Location, Created At (needs to be formatted), and Status.
    const inventoryHeaders: TableHeader[] = [
        {
            key: 'size',
            label: 'Size',
            rowClassName: 'min-w-100px',
            format: (cell) => <Link className='text-grey' to={`/inventory/items/${cell.row.id}`}>{cell.item}</Link>
        },
        {key: 'shape', label: 'Condition', format: (cell) => <span className='text-capitalize'>{cell.item}</span>},
        {key: 'price', label: 'Price', format: (cell) => TableUtilities.formatCurrency(cell.item / 100)},
        {key: 'pricePaid', label: 'Price Paid', format: (cell) => formatPricePaid(cell.item)},
        {key: 'profitAmount', label: 'Profit Total', format: (cell) => TableUtilities.formatCurrency(cell.item / 100)},
        {
            key: 'user.fullName',
            label: 'ownership',
            format: cell => formatOwnership(cell.row),
            search: (searchTerm, item) => searchValue(searchTerm, item)
        },
        {key: 'location.name', label: 'Location'},
        {key: 'createdAt', label: 'Created At', format: (cell) => tableUtilities.formatDate(cell.item)},
        {key: 'status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)}
    ]

    // Order ID, Size, Condition, Price, Profit Total, Ownership, Location, Sale Date, and Status.
    const ordersHeaders: TableHeader[] = [
        {key: 'orderSummary.orderNumber', label: 'Order Id', format: (cell) => <Link to={`/orders/${cell.row.orderSummary.id}`} className='text-grey'>{cell.item}</Link>},
        {key: 'item.size', rowClassName: 'min-w-100px', label: 'Size'},
        {key: 'item.shape', label: 'Condition'},
        {key: 'subtotal', label: 'Price', format: (cell) => TableUtilities.formatCurrency(cell.item / 100)},
        {key: 'profitTotal', label: 'Profit Total', format: (cell) => TableUtilities.formatCurrency(cell.item / 100)},
        {
            key: 'item.user.fullName',
            label: 'ownership',
            format: cell => formatOwnership(cell.row.item),
            search: (searchTerm, item) => searchValue(searchTerm, item)
        },
        {key: 'item.location.name', label: 'Location'},
        {key: 'createdAt', label: 'Sale Date', format: (cell) => TableUtilities.formatDate(cell.item)},
        {key: 'item.status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)}
    ]

    const tableActions = () => (
        <>
            {/*<Button variant={"secondary"}>*/}
            {/*    <i className="ki-duotone ki-filter fs-2"><span className="path1"></span><span*/}
            {/*    className="path2"></span></i>*/}
            {/*    Filter*/}
            {/*</Button>*/}
        </>
    )

    const handleTabSelect = (eventKey: string | null) => {
        if (Object.values(tabs).includes(eventKey as Tab)) {
            setSelectedTab(eventKey as Tab);
        } else {
            console.error(`Unknown tab selected: ${eventKey}`);

            // Default to inventory tab
            setSelectedTab(tabs.inventory)
        }
    };

    const toggleModalOpen = () => {
        setModalOpen(!modalOpen)
    }

    const handleOrdersPagination = (pagination: Pagination) => {
        setNumOrders(pagination.total)
    }
    const handleInventoryPagination = (pagination: Pagination) => {
        setNumInventory(pagination.total)
    }

    const onOrdersUpdated = (orders: any[]) => {
        setProfit(orders.reduce((acc: number, item: any) => {
            return acc + item.profitTotal
        }, 0))

        setRevenue(orders.reduce((acc: number, item: any) => {
            return acc + item.subtotal
        }, 0))
    }

    const onItemsUpdated = (items: any[]) => {
        setNumInStockInventory(items.filter(item => 
            item.status === 'active' || item.status === 'in_queue'
        ).length)
    }

    const onReprintLabel = async () => {
        toast.loading('Please wait...', {id: 'reprintLabel'})

        try {
            const response = await axios.post('/api/v1/label/product/generate', {
                product_id: itemDetails?.id
            })

            toast.success(response.data.message, {
                id: 'reprintLabel',
                duration: 8000
            })

        } catch (e: any) {
            const message = e.response.data.details?.message ?? "Failed to reprint label";

            toast.error(message, {
                id: 'reprintLabel',
                duration: 5000
            })

            throw e;
        }
    }

    return (
        <>
            <Row>
                <Col xs={'12'}>
                    <Card className={'p-10 pb-0 product'}>
                        <div className='d-block d-md-flex'>
                            <div className='product-img'>
                                {itemDetails?.imageUrl && <img src={itemDetails?.imageUrl}/>}
                            </div>

                            <div className='d-flex flex-column ps-0 ps-md-10 mt-5 mt-md-0'>
                                <h1 className='title'>{itemDetails?.primaryTitle ?? itemDetails?.title}</h1>

                                <span className='subtitle d-block'>{itemDetails?.secondaryTitle}</span>
                                <span className='subtitle d-block'>{itemDetails?.sku}</span>

                                <div className="d-flex flex-column-reverse flex-grow-1 mt-5 mt-md-0">
                                    <div className='d-flex flex-row flex-wrap'>
                                        <div
                                            className="border border-gray-300 border-dashed rounded min-w-150px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-2 fw-bolder">{numInStockInventory}</div>
                                            </div>
                                            <div className="fw-bold fs-6 text-gray-400">In-Stock Items</div>
                                        </div>
                                        <div
                                            className="border border-gray-300 border-dashed rounded min-w-150px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="fs-2 fw-bolder">{numOrders}</div>
                                            </div>
                                            <div className="fw-bold fs-6 text-gray-400">Sales</div>
                                        </div>

                                        <OutlineStat title='Revenue' value={TableUtilities.formatCurrencyCents(revenue)}/>
                                        <OutlineStat title='Profit' value={TableUtilities.formatCurrencyCents(profit)}/>
                                    </div>
                                </div>
                            </div>

                            <div className='flex-grow-1 text-md-end mt-5 mt-md-0'>
                                <Button variant={'secondary'} className='me-3' onClick={() => onReprintLabel()}>
                                    Reprint Label
                                </Button>

                                <Link to={'onboard'}>
                                    <Button variant={'primary'}>Onboard Items</Button>
                                </Link>

                            </div>
                        </div>

                        <div className='d-flex overflow-auto h-55px mt-10'>
                            <Nav activeKey={selectedTab}
                                 onSelect={handleTabSelect}
                                 className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>

                                <Nav.Item>
                                    <Nav.Link eventKey={tabs.inventory}>Inventory</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={tabs.orders}>Orders</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row className='mt-10'>
                <Col xs={'12'}>
                    <Card className='p-10 pt-5'>
                        <div style={{display: selectedTab === tabs.inventory ? 'block' : 'none'}}>
                            <PaginatedTable
                                key={'inventory'} className={'mt-5'} dataProperty={'items'}
                                paginate={true}
                                onPaginationUpdated={handleInventoryPagination}
                                onDataUpdated={onItemsUpdated}
                                headers={inventoryHeaders}
                                dataUrl={`/api/v1/item/view/product/${id}/all`}
                                filterLocally={true} actions={tableActions()}
                            />
                        </div>

                        <div style={{display: selectedTab === tabs.orders ? 'block' : 'none'}}>
                            <PaginatedTable key={'orders'} className={'mt-5'} headers={ordersHeaders}
                                            paginate={true}
                                            onPaginationUpdated={handleOrdersPagination}
                                            onDataUpdated={onOrdersUpdated}
                                            dataProperty={'orders'}
                                            dataUrl={`/api/v1/order/view/product/${id}/all`}
                                            filterLocally={true} actions={tableActions()}/>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const ProductPageWrapper = () => {
    const intl = useIntl()
    return (
        <>  
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PRODUCT_OVERVIEW'})}</PageTitle>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.PRODUCT_OVERVIEW'})} - Bind</title>
            </Helmet>
            <ProductPage/>
        </>
    )
}

export default ProductPageWrapper