import axios from "axios";
import Chart from 'react-apexcharts';

const DashboardService = {
    fetchOverview: async () : Promise<DashboardOverview> => {
        const response = await axios.get('/api/v1/dash/overview')

        return response.data;
    },
    fetchAnalytics: async (timeZone: string) : Promise<Analytics> => {
        const response = await axios.get('/api/v1/dash/analytics', {
            params: {
                tz: timeZone
            }
        });

        return response.data;
    },
    fetchAllocation: async () : Promise<Allocation> => {
        const response = await axios.get('/api/v1/dash/allocation')

        return response.data;
    }
}

export interface DashboardOverview {
    overview: {
        totalItems: number;
        awaitingItems: number;
        awaitingPayouts: number;
    };
    ownership: {
        storeOwnedItems: number;
        consignorOwnedItems: number;
    };
}

export interface Analytics {
    daily: AnalyticsData
    weekly: AnalyticsData
    monthly: AnalyticsData
}

export interface AnalyticsData {
    startDate: string;
    endDate: string;
    totalRevenue: number;
    totalOrders: number;
    totalItems: number;
    storeItems: number;
    consignorItems: number;
    storeItemProfit: number;
    consignorItemProfit: number;
    totalProfit: number;
}

export interface Allocation {
    allocation: LocationAllocation[];
}

interface LocationAllocation {
    locationName: string;
    totalItems: number;
    storeOwnedItems: number;
    consignorOwnedItems: number;
}

export default DashboardService;